html {
  font-size: 62.5%;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-family: sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}
p {
  margin-bottom: 0;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #415fac;
  border-radius: 1rem;
}
.order-notify-status {
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.notifi-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  font-weight: bold;
  font-size: 1.6rem;
  color: #415fac;
  text-decoration: underline;
  text-align: center;
  margin: 2.5rem 0;
}
.notifi-more::before {
  content: "";
  width: 7.5rem;
  height: 2px;
  background-color: #415fac;
  display: block;
}
.notifi-more::after {
  content: "";
  width: 7.5rem;
  height: 2px;
  background-color: #415fac;
  display: block;
}

.chart {
  width: 100% !important;
  height: 100% !important;
}
table thead tr th,
table tbody tr td,
table tbody tr th {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: left;
}
.btn-secondary,
.btn-primary,
.form-select {
  font-size: 1.6rem;
}

.eye {
  font-size: 1.8rem;
}
