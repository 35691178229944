.forget-section{
    background-image: url("../../assets/forms/forget-back.png");
    background-position: center;
    background-size: cover;
    padding: 75px 70px 50px 70px;
}
.forget-section .side-content{
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(5px);
}
.forget-section .side-content img{
    height: 113%;
    top: -13%;
    right: -45px;
    width: 100%;
}
@media (max-width:525px) {
    .forget .side-form h1 {
        font-size: 25px;
    }
    .side-form .create-heading{
        font-size: 23px;
        line-height: normal;
    }
}