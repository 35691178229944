.my-orders .paginate {
  display: flex;
  gap: 10px;
  align-items: center;
}
.my-orders .number {
  border: 1px solid rgba(226, 226, 226, 1);
  padding: 5px 20px;
  border-radius: 100px;
  font-weight: bold;
  font-size: 16px;
}

.my-orders .arrows img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
