.login-logo {
  cursor: pointer;
  max-width: 30rem;
  padding: 0px 0 0 10rem;
  height: 15vh;
}
.side-login {
  height: 100vh;
  background: var(--linear, linear-gradient(180deg, #415fac 0%, #415fac 100%));
  padding: 7.5rem 3.5rem 5rem 3.5rem;
  position: relative;
}
.side-login .side-content {
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(1rem);
  height: 100%;
  z-index: 99999999;
  position: relative;
}
.side-login .side-content p {
  color: var(--Black, #000);
  font-family: sans-serif;
  font-size: 4.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 6.4rem;
  padding: 10rem 2.5rem;
  width: 70%;
  text-transform: capitalize;
}
.side-login .side-content img {
  position: absolute;
  right: 0%;
  top: -5%;
  height: 105%;
  z-index: -1;
}
.side-login .audio-login {
  position: absolute;
  top: 0;
  right: 0;
}
.side-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  padding: 0px 10rem 0;
}
.side-form .form {
  width: 100%;
}
.side-form h1 {
  color: #415fac;
  font-family: sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
}
.side-form p {
  color: rgba(0, 0, 0, 0.5);
  font-family: sans-serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.side-form label {
  color: #000;
  font-family: sans-serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: bold;
  line-height: 3.2rem;
}
.side-form .form .input:nth-child(2) {
  margin-bottom: 0;
}
.side-form .forget {
  text-align: right;
  color: black;
  font-weight: bold;
  font-size: 1.7rem;
  font-family: sans-serif;
}
.side-form .forget:hover {
  text-decoration: underline;
  color: #415fac;
  transition: all ease-in-out 0.4s;
}
.side-form .form .login-rest-btn {
  width: 100%;
  height: 6.4rem;
  font-size: 2rem;
  font-weight: bold;
  outline: none;
  border: none;
  color: white;
  letter-spacing: 1px;
  border-radius: 5rem;
  margin-bottom: 8.5rem;
  background-color: #415fac !important;
}
.login-footer {
  width: 100%;
  height: 5vh;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 0px;
}
.password-toggle-icon {
  color: #415fac;
}
@media (max-width: 1024px) {
  .side-form {
    padding: 40px 0px 0 0px;
  }
  .login-logo {
    padding: 20px 0;
  }
  .side-login .side-content p {
    display: none;
  }
  .forget-section {
    padding: 50px 25px !important;
  }
}
@media (max-width: 950px) {
  .side-login {
    display: none;
  }
  .create-section {
    display: none;
  }
}
@media (max-width: 768px) {
  .side-form .forget {
    margin-bottom: 25px !important;
    text-align: left;
  }
  .side-form .form .login-button {
    margin-bottom: 0;
  }
  .password-toggle-icon {
    right: 5px;
  }
}
@media (max-width: 525px) {
  .login-logo {
    padding: 15px 0;
  }
  .side-form p {
    font-size: 14px;
    margin-bottom: 0 !important;
  }
  .side-form .forget {
    margin-bottom: 25px !important;
    text-align: right;
  }
  .side-form .form .login-button {
    margin-bottom: 0;
  }
}
