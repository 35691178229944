.services{
    overflow: hidden;
}
.services-background{
    height: 100%
}
.services-benefits{
    height: 100%;
    background-color: #1A1F2C;
    padding: 70px 50px;
}
.service-icon{
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.service-icon .icon img{
    width: 35px;
    height: 35px;
}
.services-icons .icon-details h4{
    font-size: 24px;
    font-weight: bold;
    font-family: sans-serif;
    color: white;
    letter-spacing: 1px;
    margin-bottom: 15px;
}
.services-icons .icon-details p{
    margin-bottom: 0;
    font-size: 14px;
    color: rgba(217, 217, 217, 1);
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
    line-height: 1.8;
    width: 85%;
}
@media (max-width:525px) {
    .services-benefits{
        padding: 20px;
    }
    .service-icon{
        gap: 10px;
        margin-bottom: 35px !important;
    }
    .services-icons .icon-details h4{
        font-size: 16px;
    }
    .services-icons .icon-details p{
        font-size: 12px;
    }
}