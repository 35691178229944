.speech-dash {
  height: 82vh;
}
.speech-dash .charts-sales {
  text-align: left;
  padding: 20px 15px;
  margin-bottom: 15px;
  height: 181px;
}
.speech-dash .charts-sales .no-charts {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  color: rgb(244, 162, 35);
}
.speech-dash .charts-sales p {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 30px;
}
.speech-dash .charts-sales .date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.speech-dash .charts-sales .date span {
  font-size: 12px;
  font-weight: bold;
}
.special-charts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}
.charts-info .chart-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.charts-info .chart-info .chart-rect {
  display: flex;
  align-items: center;
  gap: 10px;
}
.charts-info .chart-info span {
  font-size: 15px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .speech-dash {
    margin-bottom: 165px;
  }
  .special-charts {
    justify-content: space-between;
    gap: 0;
  }
}
@media (max-width: 525px) {
  .speech-dash .charts-sales {
    height: auto;
  }
  .speech-dash {
    margin-bottom: 85px;
  }
  .special-charts {
    gap: 10px;
  }
  .charts-info .chart-info span {
    font-size: 9px;
  }
}
