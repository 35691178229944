.notifications-page {
  min-height: 88vh;
}
.notifications-page ul {
  list-style-type: none;
  padding: 0;
}

.notifications-page li {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.notifications-page li.unread {
  background-color: #f5f5f5;
  font-weight: bold;
}

.notifications-page li.read {
  background-color: #ffffff;
}

.notifications-page small {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #888;
}

.notifications-page button {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}
