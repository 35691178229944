.benefits{
    padding-top: 50px;
}
.benefits .hero-heading{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
}
.accordion-button{
    font-size: 25px !important;
    font-weight: bold !important;
    font-family: sans-serif !important;
    color: black !important;
    padding: 30px 10px !important;
}
.accordion-body{
    font-size: 18px !important;
    line-height: 2 !important;
    font-family: sans-serif !important;
    padding: 30px 10px !important;
}
@media (max-width:525px) {
    .benefits{
        padding: 25px 0 0 0;
    }
    .benefits .hero-heading{
        margin-bottom: 20px;
    }
}