.hero-section {
  margin: 50px 0;
  padding-top: 80px;
  position: relative;
}
.gallery {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 545px;
}

.card-item {
  position: relative;
  left: 0;
  width: 2rem;
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  flex: 0.7;
}

.card-item img {
  width: 100%;
  position: relative;
  height: 100%;
  object-fit: cover;
}

.card-item:hover {
  flex: 1 1 0;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
}
.card-item span {
  position: absolute;
  left: 0;
  display: none;
  width: 100%;
  text-align: center;
  font-size: 60px;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: sans-serif;
}
.card-item .first,
.card-item .third {
  top: 65px;
}
.card-item .second {
  bottom: 25px;
}

.card-item:hover .first,
.card-item:hover .second,
.card-item:hover .third {
  display: block;
}

@media (max-width: 525px) {
  .hero-section {
    margin: 20px 0 75px 0;
    padding-top: 90px;
  }
  .gallery {
    height: 350px;
  }
  .card-item span {
    font-size: 24px;
  }
}
/* bot section */
.bot-section {
  position: relative;
}
.bot-logo {
  position: absolute;
  right: 10px;
  bottom: 0;
  cursor: pointer;
}
