.my-orders {
  padding: 1rem 2rem;
  height: auto;
}
.my-orders h1 {
  font-size: 1.7rem;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
/* inputs */
.my-orders .form-control,
.my-orders .form-select {
  border-radius: 1rem;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: sans-serif;
  letter-spacing: 1px;
  border: 1px solid rgba(226, 226, 226, 1);
  color: grey;
  margin-bottom: 1.5rem;
}
.my-orders .order-status {
  padding: 3px 1.5rem;
  border-radius: 10rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  border: 0;
}
/* dropdown toggle */
.my-orders .dropdown-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  height: 4rem;
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 1rem;
  color: grey;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: sans-serif;
  margin-bottom: 1.5rem;
}
.my-orders .dropdown-menu {
  width: 100%;
}
.my-orders .table-container {
  border: 2px solid rgba(226, 226, 226, 0.93);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  /* margin-bottom: 25px; */
}
/* table */
.my-orders table {
  margin-bottom: 0;
}
.my-orders table .status {
  border-radius: 10rem;
  color: rgba(255, 255, 255, 1);
  font-size: 1.5rem;
  font-weight: bold;
  font-family: sans-serif;
  max-width: 12.5rem;
  height: 3rem;
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 3rem;
}
.my-orders table thead tr th {
  width: 250px;
}

.my-orders table tbody td span {
  color: #129d54;
  font-weight: bold;
  font-size: 15px;
}
.my-orders table .gredients h5 {
  font-size: 1.4rem;
}
