.reviews{
    padding: 50px 0px;
}
.reviews .review{
    margin-bottom: 15px;
}
.reviews .review:nth-child(3){
    margin-bottom: 0;
}
.reviews .people-say{
    background-color: #129D54;
    padding: 50px 30px;
    border-radius: 10px;
    height: 591px;
    margin-bottom: 25px;
}
.reviews .hero-heading{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 50px;
}

.reviews .hero-heading span{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: sans-serif;
}
.reviews .people-say h1{
    color: #fff;
    margin-bottom: 35px;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: sans-serif;
}
.reviews .people-say p{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.8;
    font-family: sans-serif;
}
.review-scroll{
    height: 591px;
    overflow-y: scroll;
}

.review-scroll::-webkit-scrollbar{
    display: none;
}

@media (max-width:525px) {
    .reviews{
        padding: 25px 5px;
    }
    .reviews .heading{
        margin-bottom: 35px;
    }
    .reviews .people-say{
        height: 300px;
        padding: 30px 20px;
    }
    .reviews .people-say h1{
        font-size: 24px;
    }
    .reviews .people-say p{
        font-size: 16px;
    }
    .review{
        gap: 10px;
    }
    .reviews .review img{
        width: 35px;
        height: 35px;
    }
    .review-container .person-details h1{
        font-size: 18px !important;
    }
    .review-container .person-details p{font-size: 12px !important;}
    .review-container .person-result{
        gap: 15px !important;
    }
    .review-container .person-result h1{
        font-size: 24px !important;
    }
    .review-container .person-result p{
        font-size: 10px !important;
    }
}