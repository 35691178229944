.category-name:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #415fac !important;
  transition: all ease-in-out 0.5s;
}
.cat-info {
  border: 2px solid rgba(226, 226, 226, 0.93);
  border-radius: 15px;
  padding: 15px;
  margin-top: 25px;
  height: 115px;
}
.cat-info .cat-top,
.cat-info .cat-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cat-info .cat-top p {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color: #129d54;
}

.cat-info .cat-top .cat-icons {
  display: flex;
}
.cat-info .cat-bottom p {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

.cat-info .cat-bottom .cat-imgs img {
  margin-left: -10px;
}
.cat-info .cat-bottom .cat-imgs span {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: black;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  margin-left: -10px;
  opacity: 0.7;
}
.cat-icons img {
  cursor: pointer;
}
@media (max-width: 525px) {
  .cat-info .cat-top p {
    font-size: 14px;
  }
  .nav-tabs .nav-link {
    width: 100%;
    padding: 15px 0;
  }
  .nav-item {
    width: 50%;
  }
}
