.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
}

.error-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.error-page p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.error-page button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #721c24;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-page button:hover {
  background-color: #501414;
}
