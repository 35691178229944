.signup{
    height: 100vh;
    background-color: #415FAC;
    position: relative;
}
.signup .stt-login-btn{
    background: linear-gradient(45deg, #415FAC 0%, #415FAC 100%);
}
.signup .audio{
    position: absolute;
    top: 0;
    right: 0;
    max-width: 250px;
    max-height: 150px;
}
.signup .login-logo{
    padding: 20px 0 15px 55px;
}
.signup .side-form {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 20px;
    padding: 10px 50px;
    margin-bottom: 20px;
    z-index: 9;
    position: sticky;
    height: 75vh;
}
.signup .form .input{
    padding: 20px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.5);
}
.signup-scroll{
    overflow-y: scroll;
    justify-content: flex-start;
}
.signup-scroll::-webkit-scrollbar{
    display: none;
}
@media (max-width:525px) {
    .signup .side-form{
        padding: 10px 20px;
        margin-bottom: 22px;
    }
    .signup .login-logo{
        padding: 15px 20px 25px;
    }
    .signup .audio{
        display: none;
    }
}