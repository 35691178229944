.hero-details{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
}
.hero-details .hero-heading{
    display: flex;
    gap: 10px;
    align-items: center;
}
.hero-heading span{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
.hero-details h1{
    font-size: 56px;
    font-weight: bold;
    font-family: sans-serif;
}
.hero-details p{
    font-size: 24px;
    font-weight: 400;
    font-family: sans-serif;
    color: #000;
}
.hero-details .hero-btn{
    border: 2px solid #415FAC;
    background-color: #415FAC;
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    padding: 20px 45px;
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 1px;
    transition: all ease-in-out 0.3s;
}
.hero-details .hero-btn:hover{
    border: 2px solid #415FAC;
    background-color: #415FAC;
    color: #fff;
    transition: all ease-in-out 0.4s;
    padding: 15px 35px;
}
@media (max-width:525px) {
    .hero-details{
        gap: 30px;
    }
    .hero-details h1{
        font-size: 32px;
    }
    .hero-details .hero-btn{
        font-size: 15px;
        padding: 20px 44px;
        margin-bottom: 15px;
    }
}