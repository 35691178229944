.trial-expired-notification {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: #ff3434; /* Bright red background */
  padding: 2rem;
  border-radius: 2rem;
  color: #fff;
  font-family: Arial, sans-serif;
  width: 100%;
  height: 18rem;
  margin-block-end: 2.5rem;
}

.expired-content {
  flex: 1;
}

.expired-text {
  font-size: 2.3rem;
  margin: 0;
  font-weight: bold;
  margin-block-end: 1.5rem;
  text-transform: capitalize;
}

.expired-note {
  font-size: 2rem;
  color: #ffe5e5; /* Lighter red for the note */
}

.upgrade-button {
  background-color: #1a1f2c; /* Dark button background */
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 10rem;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
}

.expired-btn {
  background-color: #131723 !important;
}

.upgrade-button:hover {
  background-color: #333; /* Darker shade on hover */
}
