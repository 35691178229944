.nav-section {
  border-bottom: 2px solid rgba(226, 226, 226, 0.93);
  position: relative;
}
.notify-group {
  position: absolute;
  top: 9rem;
}
.nav-heading {
  padding: 1rem 2rem;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 1.5rem;
}
.nav-heading img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}
.nav-heading h2 {
  font-size: 2.5rem;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  text-transform: capitalize;
  color: #415fac;
}
.nav-heading p {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
}
.nav-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  height: 100%;
}

/* search button */
.group {
  display: flex;
  line-height: 2.8rem;
  align-items: center;
  position: relative;
  width: 25rem;
}

.input {
  width: 100%;
  height: 4rem;
  line-height: 2.8rem;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid rgba(226, 226, 226, 1);
  border-radius: 1rem;
  outline: none;
  background-color: transparent;
  color: #0d0c22;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #9e9ea7;
}

.input:focus,
input:hover {
  outline: none;
  border-color: rgba(226, 226, 226, 1);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgba(226, 226, 226, 1);
}

.group .icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

.nav-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-dropdown > img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
/* drop down list */
.paste-button {
  position: relative;
  display: block;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.button {
  background-color: transparent;
  color: #212121;
  font-size: 1.5rem;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 1.5rem;
  cursor: pointer;
  text-transform: capitalize;
}

.dropdown-content {
  display: none;
  font-size: 1.3rem;
  position: absolute;
  right: 0;
  z-index: 1;
  min-width: 12.5rem;
  background-color: #fff;
  border: 2px solid rgba(226, 226, 226, 1);
  border-radius: 0px 15px 15px 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  padding: 1rem 1rem;
  text-decoration: none;
  display: block;
  transition: 0.1s;
  font-weight: bold;
}

.dropdown-content a:hover {
  background-color: rgba(226, 226, 226, 1);
  color: #212121;
}

.dropdown-content a:focus {
  background-color: #212121;
  color: #fff !important;
}

.dropdown-content #top:hover {
  border-radius: 0px 1.3rem 0px 0px;
}

.dropdown-content #bottom:hover {
  border-radius: 0px 0px 1.3rem 1.3rem;
}

.paste-button:hover button {
  border-radius: 1.5rem 1.5rem 0px 0px;
}

.paste-button:hover .dropdown-content {
  display: block;
}

/* notifications box */
.notify-group {
  background-color: white;
  border-radius: 1rem;
  width: 30rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 7rem;
  right: 2.5rem;
  z-index: 1;
  padding: 1rem 1.5rem;
}
.notify-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notify-title span {
  background-color: #415fac;
  color: white;
  border-radius: 10rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
}
