.dashboard .total-sales {
  background-color: rgba(66, 95, 171, 0.2);
  border-radius: 2rem;
  height: 15.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
}
.dashboard .total-tokens {
  background-color: rgba(244, 162, 35, 0.2);
}
.dashboard .total-sales .total-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #131723;
  text-decoration: underline;
  cursor: pointer;
}
.dashboard .img-price {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: #415fac;
  padding: 0.7rem;
}
.dashboard .token-price {
  background-color: #f4a223;
}

.dashboard .total-revenu img {
  background-color: #129d54;
}
.dashboard .total-sales h3,
.dashboard .charts-sales h3,
.dashboard .total-revenu h3 {
  font-size: 2.4rem;
  font-weight: bold;
  font-family: sans-serif;
  margin-bottom: 0rem;
}
.dashboard .total-sales p,
.dashboard .charts-sales p,
.dashboard .total-revenu p {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: capitalize;
}
.total-revenu,
.charts-sales,
.fav-items {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 2rem;
  padding: 3.2rem 2.5rem;
  /* text-align: center; */
  height: 40rem;
}
.charts-sales {
  position: relative;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
/* favourite items */
.fav-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.fav-heading h2 {
  font-size: 2rem;
  font-weight: bold;
  font-family: sans-serif;
}
.fav-heading p,
.btn-more-sales {
  background-color: #129d54;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
}
.btn-more-sales {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.fav-items .fav-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
}
.dashboard .table-container {
  border-radius: 2rem;
  margin-bottom: 0;
}

/* spinner */
/* HTML: <div className="loader"></div> */
.spinner {
  height: 82vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 5rem;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, #25b09b 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}
