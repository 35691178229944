/* step section */
.step-section .left-section h1{
    font-size: 40px;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 1px;
    color: white;
    margin-bottom: 35px;
    margin-top: 75px;
}
.step-section .left-section p{
    font-size: 18px;
    font-weight: 400;
    font-family: sans-serif;
    color: rgba(255, 255, 255, 1);
    width: 75%;
    line-height: 1.8;
    margin-bottom: 100px;
}
.step-section .left-section .steps-arrow{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
}
.step-section .left-section .steps-arrow svg{
    cursor: pointer;
}
.step-section .left-section .steps-progress-container{
    height: 4px;
    background-color: aliceblue;
    width: 50%;
    margin-top: 15px;
    border-radius: 10px;
    position: relative;
}
.step-section .left-section .steps-progress{
    position: absolute;
    content: "";
    width: 35%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #415FAC;
}

@media (max-width:525px) {
    .step-section .left-section h1{
        font-size: 30px;
        margin-top: 40px;
    }
    .step-section .left-section p{
        width: 100%;
        font-size: 15px;
    }
    .step-section .left-section .steps-arrow{
        width: 100%;
    }
    .step-section .left-section .steps-progress{
        width: 100%;
    }
}