.audio-box{
    border: 3px dashed #bbbb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 10px;
    padding: 15px 0;
    height: 185px;
    margin-bottom: 15px;
}
.audio-box img{
    width: 25px;
    height: 25px;
}
.audio-box p{
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 1px;
    text-align: center;
}
.audio-box input{
    width: 85%;
    margin: 0 auto;
    border-radius: 100px;
    outline: none;
    padding: 5px 0px;
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}
.audio-box button{
    width: 85%;
    margin: 0 auto;
    /* background-color: black; */
    color: white;
    border: 0;
    border-radius: 100px;
    padding: 10px 0px;
    font-size: 16px;
    letter-spacing: 1px;
}
.speech-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}
.speech-heading h2{
    font-size: 17px;
    font-weight: bold;
}
.speech-heading img{
    cursor: pointer;
}
textarea{
    padding: 10px !important;
    resize: none;
    font-size: 15px;
    font-weight: bold;
    outline: none;
}
.transcripe{
    background-color: #D9D9D9;
    width: 100%;
    border-radius: 100px;
    border: none;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 0px;
    margin-bottom: 20px;
}
.audio-box .input-group{
    width: 85% !important;
}
.audio-box .input-group input{
    height: 100%;
    padding: 10px 0 10px 20px !important;
}
.audio-box .input-group label{
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    cursor: pointer;
}
@media (max-width:525px) {
    .audio-box p{
        font-size: 18px;
    }
    .audio-box input{
        width: 95%;
        padding: 10px 0;
        font-size: 15px;
    }
    .audio-box button{
        width: 95%;
        padding: 10px 0px;
        font-size: 15px;
    }
}