.navbar-section {
  padding: 30px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 9999999;
}

.navbar-section .user-links ul {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
}
.navbar-section .user-links ul a {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.5);
}

.navbar-section .user-links ul .active {
  border-radius: 100px;
  background: #131723;
  padding: 10px 35px;
  font-weight: bold;
  color: #ffffff;
}

.navbar-section .login-btn {
  border: 2px solid #415fac;
  background-color: #415fac;
  color: white;
  padding: 15px 44px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
}

.navbar-section .login-btn:hover {
  background-color: #fff;
  color: #415fac;
  transition: all ease-in-out 0.4s;
  border: 2px solid #415fac;
}
.dots {
  display: none;
}
.navbar-section .logo img {
  width: 100%;
}
@media (max-width: 991px) {
  .navbar-section {
    padding: 20px 0;
  }
  .navbar-section .user-links {
    display: none;
  }
  .navbar-section .login-section {
    display: none;
  }
  .dots {
    display: flex;
    justify-content: flex-end;
  }
  .dots svg {
    font-size: 30px;
    cursor: pointer;
  }
  .sidemenubar {
    width: 95%;
    position: absolute;
    right: 10px;
    top: 80px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 35px 25px;
    text-align: center;
    transition: all ease-in-out 0.4s;
  }
  .sidemenubar .user-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .sidemenubar .user-links a {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .sidemenubar .user-links a.active {
    border-radius: 100px;
    background: rgba(255, 215, 52, 1);
    padding: 10px 35px;
    font-weight: bold;
    color: #ffffff;
  }
}
