.contact-us {
  margin: 100px 0;
  padding-top: 80px;
}
.contact-us .Toastify {
  z-index: 999999999;
}
.contact-us .hero-details p {
  color: #000;
  font-family: sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}
/* form style */
.form-container {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(1, 9, 54, 0.16);
  padding: 25px 15px;
  border: 5px solid rgb(255, 255, 255);
}

.form-container .heading {
  text-align: left;
  font-weight: 900;
  font-size: 20px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 35px;
}

.form {
  margin-top: 20px;
}
.form .custom-input {
  width: 100%;
  margin-left: 10px;
  border: none;
  outline: none;
  font-weight: bold;
  border-left: 2px solid rgb(170, 170, 170);
  padding-left: 10px;
}
.form .custom-input:hover {
  box-shadow: none;
}
.form .custom-input::placeholder {
  color: rgb(170, 170, 170);
  font-weight: bold;
}
.form .input {
  width: 100%;
  background: white;
  font-size: 1.7rem;
  font-weight: bold;
  border: none;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-bottom: 3.5rem;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.16);
  border: 2px solid transparent;
}
.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
  font-weight: bold;
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
  font-weight: bold;
}

.form .input:focus {
  outline: none;
  border-inline: 2px solid rgba(0, 0, 0, 0.16);
}

.form .login-button {
  display: block;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  background: linear-gradient(45deg, #129d54 0%, #129d54 100%);
  color: #ffffff;
  padding-block: 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px #00000029;
  border: none;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: 0 0 10px #00000029;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: 0 0 10px #00000029;
}

@media (max-width: 768px) {
  .hero-details {
    gap: 25px;
  }
  .contact-us {
    padding-top: 50px;
  }
}
@media (max-width: 525px) {
  .contact-us {
    margin: 20px 0 0 0;
    padding-top: 90px;
  }
  .contact-us .hero-details p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: inherit;
  }
}
