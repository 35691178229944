.speech .hero-details {
  gap: 35px;
}

.speech-img {
  position: relative;
  /* height: 545px; */
}
.speech-img .audio {
  position: absolute;
  top: 17%;
  left: 10px;
}
.speech-img .img-girl {
  width: 100%;
  height: 100%;
}
.speech .steps {
  padding: 50px 0;
  background-color: #000;
}
.speech .steps .hero-heading span {
  color: white;
  text-transform: uppercase;
}
/* feature */
.features {
  padding: 25px 0;
}
.speech .hero-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  /* margin: 75px 0 35px 0; */
}
/* clients */
.clients {
  background-color: rgba(248, 248, 248, 1);
}
.clients .clients-img::-webkit-scrollbar {
  display: none;
}
.clients .hero-heading {
  margin: 25px 0;
}
.clients-img {
  height: 540px;
  overflow-y: scroll;
}
.clients-img img {
  height: 190px;
  padding: 35px;
  border-radius: 10px;
  background-color: #ffd734;
}
.img-padding {
  padding-top: 30px;
}
.industry {
  margin: 50px 0;
}
/* right section */
.right-section .transcript,
.right-section .uploaded {
  border: 2px dashed rgba(217, 217, 217, 1);
  border-radius: 10px;
  padding: 25px;
}
.right-section .transcript p {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 400;
  font-family: sans-serif;
  letter-spacing: 1px;
  text-align: justify;
  line-height: 40px;
  margin-bottom: 100px;
}
.right-section .steps-btn {
  margin: 25px 0;
  width: 100%;
  padding: 10px 0;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
  background-color: #415fac;
  color: #fff;
  border-radius: 100px;
}
.right-section .uploaded {
  text-align: center;
  padding: 35px 0;
}
.right-section .uploaded > p {
  font-size: 32px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: white;
  margin-bottom: 65px;
}
.audio-progress span {
  font-size: 18px;
  color: white;
  font-family: sans-serif;
}
.audio-progress p {
  width: fit-content;
  margin: auto;
  background: linear-gradient(180deg, #f4a223 0%, #ffd734 100%),
    linear-gradient(90.13deg, #f4a223 0.12%, #ffd734 99.9%);
  font-size: 20px;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  padding: 5px 120px;
  border-radius: 100px;
}
.audio-progress-2 {
  width: 75%;
  height: 65px;
  margin: auto;
  border: 1px solid white;
  border-radius: 100px;
  overflow: hidden;
}
.audio-progress-2 .input-group {
  height: 100%;
}
.audio-progress-2 .input-group .form-control {
  background-color: transparent;
  border: 0;
  padding: 20px 45px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
  outline: none;
}
.audio-progress-2 .input-group .input-group-text {
  cursor: pointer;
  border-radius: 100px !important;
  padding: 0px 30px;
  background-color: rgba(255, 255, 255, 0.2) !important;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border: 1px solid white;
}
.audio-progress button {
  width: 30%;
  padding: 10px 0px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  border: 1px solid white;
  color: white;
}
@media (max-width: 525px) {
  .speech {
    padding-top: 90px !important;
  }
  .speech .hero-heading {
    margin: 20px 0;
  }
  .speech-img .audio {
    width: 70px;
    top: 28%;
    left: 7px;
  }
  .clients-img img {
    height: 100px;
  }
  .step-section .left-section h1 {
    font-size: 30px;
    margin-top: 40px;
  }
  .step-section .left-section p {
    width: 100%;
    font-size: 15px;
  }
  .step-section .left-section .steps-arrow {
    width: 100%;
  }
  .step-section .left-section .steps-progress-container {
    width: 100% !important;
    margin-bottom: 50px;
  }
  .right-section .transcript,
  .right-section .uploaded {
    padding: 10px 15px;
  }
  .right-section .transcript p {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 50px;
  }
  .right-section .uploaded > p {
    font-size: 16px;
  }
  .audio-progress-2 {
    display: flex;
    width: 100%;
  }
  .audio-progress-2 input {
    width: 70%;
    font-size: 12px;
    padding: 10px;
  }
  .audio-progress button {
    font-size: 14px;
    padding: 10px 0px;
    width: 30%;
  }
  .audio-progress p {
    width: 100%;
    padding: 10px 0;
  }
}
