.sideMenu {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 2rem;
  border-right: 3px solid rgba(226, 226, 226, 1);
  position: relative;
}

.sideMenu .logo {
  margin: 0px 0 10rem 0;
  width: 100%;
}
.sideMenu ul a {
  padding: 1rem 0;
  margin-bottom: 2.5rem;
  cursor: pointer;
  position: relative;
  display: block;
}
.sideMenu ul .active::before {
  content: "";
  position: absolute;
  width: 0.6rem;
  left: 0;
  bottom: 0;
  border-radius: 1rem;
  height: 100%;
  background-color: #415fac;
}
.sideMenu .logOut-icon {
  position: absolute;
  bottom: 1.5rem;
  width: 100%;
  cursor: pointer;
}
