/* card */
.cardd {
    padding: 20px;
    width: 100%;
    border-radius: 20px;
    background: #e8e8e8;
    box-shadow: 5px 5px 6px #dadada,
                   -5px -5px 6px #f6f6f6;
    transition: 0.4s;
    margin-bottom: 25px;
  }
  
  .cardd:hover {
    translate: 0 -10px;
  }
  
  .cardd-title {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin: 15px 0;
    font-family: sans-serif;
  }
  
  .cardd-image {
    height: 300px;
    background-color: #c9c9c9;
    border-radius: 15px;
    box-shadow: inset 8px 8px 10px #c3c3c3,
              inset -8px -8px 10px #cfcfcf;
  }
  
  .cardd-body {
    color: rgb(31, 31, 31);
    font-size: 15px;
    font-family: sans-serif;
  }

  @media (max-width:525px) {
    .cardd-image{
      height: 250px;
    }
    .cardd-title{
      font-size: 18px;
    }
  }