.modal-backdrop {
  --bs-backdrop-bg: rgba(0, 0, 0, 0.4) !important;
}
.modalComp-footer {
  border: none !important;
  justify-content: center !important;
  gap: 30px;
}
.modalComp-footer .btn-danger,
.modalComp-footer .btn-dark {
  border-radius: 100px;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.modalComp-footer .btn-dark {
  background-color: rgba(187, 187, 187, 1);
  border: 1px solid rgba(187, 187, 187, 1);
}
.modalComp-body {
  text-align: center;
  padding: 50px 0 15px 0 !important;
}
.modalComp-body .heading {
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.modalComp-body .sub-heading {
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
}
