.create-section .side-content{
    backdrop-filter: blur(5px);
}
.create-section .side-content img{
    width: 100%;
    right: -6%;
}
.audio-create{
    position: absolute;
    top: 0;
    left: -65px;
    width: 80%;
}
.create-section{
    background-color: #129D54 !important;
    height: 100vh;
    padding: 75px 35px 50px 35px;
    position: relative;
}
.create-section .side-content{
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    height: 100%;
    z-index: 99999999;
    position: relative;
}
.create-section .side-content img{
    position: absolute;
    width: 100%;
    height: 100%;
    right: -6%;
}
.side-form .form .back-green{
    background-color: #129D54 !important;
}