.order-details .table-container {
  /* padding: 35px 50px 35px 50px; */
}
.my-orders .first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 25px;
  padding-bottom: 15px;
}
.my-orders .first-row:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0px;
}
.my-orders .first-row .first-col p,
.my-orders .first-row .second-col p {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  margin-bottom: 15px;
}
.my-orders .first-row .second-col p {
  text-align: right;
  text-transform: capitalize;
}
.my-orders .first-row .first-col h2 {
  font-size: 24px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 1px;
}

.my-orders .first-row .second-col .completed {
  color: white;
  border-radius: 100px;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  font-family: sans-serif;
}

@media (max-width: 525px) {
  .order-details .table-container {
    padding: 20px;
  }
  .my-orders .first-row .second-col p {
    font-size: 12px;
  }
  .my-orders .first-row .first-col h2 {
    font-size: 10px;
  }
  .first-row-mobile {
    flex-direction: column;
  }
  .my-orders .first-row .second-col .p-mobile {
    text-align: left;
  }
  .my-orders .first-row .second-col .p-none {
    display: none;
  }
  .first-col-mobile {
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    width: 100%;
    margin-bottom: 15px;
  }
}
