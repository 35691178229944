.speech-reset{
    background-color: #fff;
}
.speech-reset .side-form p{
    font-weight: bold;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 35px;
}
@media (max-width:525px) {
    .speech-reset .side-form h1{
        font-size: 30px;
    }
    .speech-reset .side-form p{
        font-size: 15px;
    }
}