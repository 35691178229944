.review{
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 15px 20px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.review-container .person-heading h3{
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    font-family: sans-serif;
    margin-bottom: 3px;
}
.review-container .person-heading h4{
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    font-family: sans-serif;
}
.review-container .person-details h1{
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    font-family: sans-serif;
    margin-bottom: 3px;
}

.review-container .person-details p{
    color: rgba(0, 0, 0, 0.50);
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.review-container .person-result{
    display: flex;
    align-items: center;
    gap: 50px;
}
.review-container .person-result h1{
    color: #129D54;
    font-family: sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.review-container .person-result p{
    color: rgba(0, 0, 0, 0.21);
    font-family: sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}