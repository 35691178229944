.empty-users {
  font-weight: bold;
  font-size: 1.8rem;
  margin: 1rem 0;
  color: red;
}
.add-new-role {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 11.5rem;
  color: white;
  gap: 1.5rem;
  font-size: 2rem;
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
}
.search-add .login-btn {
  border: 2px solid #415fac;
  background-color: #415fac;
  color: white;
  padding: 0px 1rem;
  height: 4.8rem;
  border-radius: 10rem;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: capitalize;
}
