.faq-section{
    background-color: #000;
    padding: 50px 0;
}
.faq-section .hero-heading{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.faq-section .hero-heading span{
    color: white;
}
.faq-section h1{
    text-transform: capitalize;
    color: #FFF;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}