.footer {
  padding: 50px 0 0 0;
}
.footer-logo img {
  cursor: pointer;
}
.footer .footer-logo p {
  color: #000;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  /* margin-bottom: 0; */
}
.footer .footer-links {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 15px;
}
.footer .footer-links a {
  display: block;
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  cursor: pointer;
}
.footer .footer-links a:hover {
  color: rgba(255, 215, 52, 1);
  text-decoration: underline;
  transition: all ease-in-out 0.3s;
}
.social {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 15px;
}
.social h1 {
  color: black;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
}
.social .social-icons {
  display: flex;
  gap: 5px;
}
.social .social-icons img {
  cursor: pointer;
}

/* footer social icons */
ul {
  list-style: none;
}

.example-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.example-1 .icon-content {
  position: relative;
}
.example-1 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-1 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}
.example-1 .icon-content .link {
  width: 40px;
  height: 40px;
  color: #4d4d4d;
  transition: all 0.3s ease-in-out;
}
.example-1 .icon-content .link:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.example-1 .icon-content .link svg {
  width: 25px;
  height: 25px;
}
.example-1 .icon-content .link[data-social="facebook"]:hover {
  color: #1877f2;
}
.example-1 .icon-content .link[data-social="instgram"]:hover {
  color: #c32aa3;
}
.example-1 .icon-content .link[data-social="twitter"]:hover {
  color: #000;
}

/* facebook btn */
.footer .copy {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
