.products{
    background: #000;
    padding-bottom: 60px;
}
.products .hero-heading{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 60px 0;
}
.products .hero-heading span{
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.products .smart-ordering{
    position: relative;
    background-color: #415FAC;
    border-radius: 10px;
    padding: 25px;
    height: 100%;
}
.products .smart-ordering h1{
    color: #fff;
    font-size: 48px;
    font-family: sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.products .smart-ordering h3{
    color: #fff;
    font-size: 24px;
    font-family: sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 50px;
}
.products .smart-ordering p{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    text-transform: capitalize;
    margin-bottom: 50px;
    position: relative;
    z-index: 999;
}
.products .smart-ordering .zigzag{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
}

.products .watch-demo{
    color: #fff;
    z-index: 9999;
    position: sticky;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.products .watch-demo .watch-img-container{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 2px solid transparent;
}
.products .watch-demo img{
    width: 25px !important;
    height: 25px;
}

.products .watch-demo .watch-img-container:hover{
    border: 2px solid #fff;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
}

/* smart answering */
.products .smart-answering{
    background-image: url(../../assets/products/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    position: relative;
    height: 100%;
}
.products .smart-answering .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.50);
    padding: 0 25px;
}
.products .smart-answering h1{
    margin-top: 160px;
    font-size: 48px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    font-family: sans-serif;
    margin-bottom: 20px;
}
.products .smart-answering h3{
    font-size: 24px;
    font-family: sans-serif;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 30px;
}
.products .smart-answering p{
    font-size: 18px;
    font-family: sans-serif;
    color: white;
    text-transform: capitalize;
}
/* iframe */
.ReactModal__Overlay {
    width: 50%;
    height: 50%;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 99999 !important;
}
.ReactModal__Content{
    inset: 0 !important;
    border: 0 !important;
    background: none !important;
    overflow: initial !important;
    border-radius: 0px !important;
    outline: none !important;
    padding: 0px !important;
}
.close-iframe{
    position: absolute;
    right: 10px;
    top: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 20px;
    font-weight: bold;

}
@media (max-width:525px) {
    .products .smart-ordering h1,.products .smart-answering h1{
        font-size: 24px;
        margin-bottom: 20px;
    }
    .products .smart-ordering h3,.products .smart-answering h3{
        font-size: 18px;
        margin-bottom: 20px;
    }
    .products .smart-ordering p, .products .smart-answering p{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .products .smart-ordering .zigzag{
        bottom: 10px;
        width: 125px;
    }
    .products .smart-answering{
        height: 350px;
        margin-top: 20px;
    }
    .products .smart-answering .overlay{
        padding: 25px;
    }
    .products .smart-answering h1{
        margin-top: 0;
    }
}
@media (max-width: 320px) {
    .products .smart-answering {
        height: 450px;
    }
}