.brands {
  margin-top: 75px;
  padding: 75px 0;
  background-color: rgba(248, 248, 248, 1);
}
.brands .badge {
  background-color: #129d54;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: bold;
  width: fit-content;
}
.brands .smile-face {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 35px;
}
.brands .smile-face .badge {
  background-color: #415fac;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 15px;
}
.brands .brands-heading {
  background-image: url("../../assets/brands/border.png");
  background-size: contain;
  margin: 30px auto;
  text-align: center;
  background-repeat: no-repeat;
  padding: 78px 0;
  background-position: center;
  color: #129d54;
  font-family: sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
}
.brands .brands-desc {
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  width: 65%;
  margin: auto;
  margin-bottom: 100px;
}
/* react slick */
.img-container {
  width: 95%;
  margin: auto;
  margin-bottom: 25px;
  background-color: white;
  height: 150px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
}
.slick-slide img {
  width: 95%;
  height: 100%;
  margin: auto;
}
.slick-dots li.slick-active button:before {
  color: #ffd734;
}
.slick-dots li button:before {
  font-size: 12px;
}
.slick-arrow {
  display: none !important;
}
@media (max-width: 525px) {
  .brands .brands-desc {
    width: 100%;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 35px;
  }
  .brands .brands-heading {
    width: 100%;
    font-weight: bold;
    margin: 35px auto;
    padding: 0;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.7;
    background-image: none;
  }
  .brands .badge {
    padding: 10px 15px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 0px;
  }
  .brands .smile-face .badge {
    font-size: 12px;
    padding: 10px 15px;
  }
}
@media (max-width: 768px) {
  .img-container {
    height: 85px;
    margin-bottom: 10px;
  }
}
