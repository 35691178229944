.my-orders .item-img{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 35px;
}
.my-orders .item-img .img-delete{
    position: relative;
}
.my-orders .item-img img{
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%;
}
.my-orders .item-img .delete-icon-img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 25%);
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    color: white;
    background-color: rgba(255, 215, 52, 1);
}
.my-orders .upload-photo{
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: monospace;
}
.my-orders .upload-photo .upload{
    color: #134A7C;
    text-decoration: underline;
    cursor: pointer;
}
@media (max-width:525px) {
    .my-orders .item-img{
        flex-direction: column;
        justify-content: center;
    }
    .my-orders .upload-photo{
        text-align: center;
    }
    .speech-dash{
        height: auto;
    }
}