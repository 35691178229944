.icon-card {
    width: 100%;
    height: 375px;
    background-color: #1A1F2C;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
  }
  
  /* .icon-card .icon img {
    width: 65px;
    height: 65px;
  } */
  
  .icon-card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 4px;
    font-size: 32px;
    font-family: sans-serif;
  }
  
  .icon-card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  /* .icon-card:hover {
    height: 350px;
  }
  
  .icon-card:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 40px;
  }
  
  .icon-card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  } */
