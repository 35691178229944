.about-us {
  background-image: url("../../assets/aboutUs/about_back.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-section {
  padding-top: 125px;
}
.about-us .navbar-section {
  background-color: #171e2c;
}
.about-us .navbar-section .user-links ul {
  background-color: rgba(2555, 255, 255, 0.2);
}
.about-us .navbar-section .user-links ul a {
  color: #fff;
  opacity: 0.5;
  font-weight: 500;
}
.about-us .navbar-section .user-links ul .active {
  color: #ffffff;
  opacity: 1;
}
.about-us .navbar-section .login-btn {
  background-color: #415fac;
  color: #fff;
}
.about-us .hero-heading {
  /* margin-bottom: 35px; */
}
.about-us .hero-details h1 {
  color: white;
}
.about-us .hero-heading span {
  color: white;
}
.about-us .hero-details p {
  color: white;
  opacity: 0.9;
}
.about-us .hero-details .hero-btn {
  background-color: white;
  color: black;
  margin-bottom: 75px;
}
.navbar-section .login-btn:hover,
.about-us .hero-details .hero-btn:hover {
  background-color: #415fac;
  color: #ffffff;
  border-color: #415fac;
}
.about-us .dots svg {
  color: white;
}

/* story */
.story {
  padding: 100px 0;
}
.story .story-img {
  width: 100%;
  height: 450px;
}
.story .hero-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 75px 0 35px 0;
}
.story .stoty-desc {
  font-size: 24px;
  font-family: sans-serif;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
}
/* numbers */
.numbers {
  background-color: rgba(248, 248, 248, 1);
  padding: 100px 0;
}
.numbers .numbers-heading {
  font-size: 48px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 75px;
}
.numbers-success .cardd {
  background-color: #129d54;
  height: 387px;
}
.numbers-success .cardd .cardd-image {
  height: 219px;
}
.numbers-success .cardd .cardd-title {
  color: white;
  font-size: 40px;
  margin: 0;
  margin-top: 20px;
}
.numbers-success .cardd .cardd-body {
  color: rgba(255, 255, 255, 0.8);
}
.improvment .cardd {
  background-color: white;
}
.improvment .cardd .cardd-image {
  background-color: white;
  box-shadow: none;
}
.improvment .cardd .cardd-title {
  color: black;
}
.improvment .cardd .cardd-body {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.operations {
  position: relative;
}
.operations .cardd {
  background-color: #415fac;
  height: 387px;
}
.operations .cardd-title {
  font-size: 40px;
  color: #fff;
}
.operations .cardd-body {
  width: 70%;
  font-size: 24px;
  font-weight: bold;
  font-family: sans-serif;
  line-height: 1.8;
  text-transform: capitalize;
  color: #fff;
}
.operations img {
  position: absolute;
  bottom: -60px;
  right: -40px;
  border: 30px solid white;
  border-radius: 50%;
  width: 270px;
  height: 270px;
}
@media (max-width: 1024px) {
  .operations .cardd-body {
    width: 100%;
    font-size: 20px;
  }
  .operations img {
    width: 175px;
    height: 175px;
    bottom: -45px;
    right: -20px;
  }
}

@media (max-width: 525px) {
  .about-us {
    padding-top: 40px;
  }
  .about-section {
    padding-top: 50px;
  }
  .about-us .hero-heading {
    margin-bottom: 0;
  }
  .numbers {
    padding: 60px 0;
  }
  .numbers .numbers-heading {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .story {
    padding: 50px 0;
  }
  .about-us .hero-details h1 {
    font-size: 30px;
  }
  .about-us .hero-details p {
    font-size: 16px;
  }
  .story .story-img {
    height: 250px;
    margin-bottom: 20px;
  }
  .story .hero-heading {
    margin: 35px 0;
  }
  .story .stoty-desc {
    font-size: 14px;
    font-weight: bold;
    text-align: justify;
    font-family: sans-serif;
    text-transform: capitalize;
  }
  .operations .cardd {
    height: 280px;
  }
  .operations .cardd-body {
    width: 100%;
    font-size: 14px;
  }
  .operations img {
    width: 140px;
    height: 140px;
    border: 15px solid white;
    bottom: -40px;
    right: -10px;
  }
}
