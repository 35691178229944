.stt-settings p , label{
    font-size: 20px;
    font-family: "product-sans";
    letter-spacing: 1px;
}
.stt-settings label{
    font-weight: bold;
}
.stt-settings .add-payment{
    width: 380px;
    height: 60px;
    background-color: #FFD734;
    font-size: 20px;
    font-weight: bold;
    font-family: "product-sans";
    letter-spacing: 1px;
    border-radius: 10px;
    border: none;
    outline: none;
}
/* toggle button */
.toggle {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
  }
  
  .toggle-btn {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    transition: transform 0.3s;
  }
  
  .toggle-btn.on {
    transform: translateX(30px);
  }
  
  .toggle-label-text {
    display: inline-block;
    margin-left: 10px;
  }
  .toggle-label input{
    display: none;
  }
  .auto-renewal{
    display: flex;
    align-items: center;
    gap: 90px;
    margin: 35px 0;
  }
  .auto-renewal .toggle-label{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media (max-width:525px) {
    .stt-settings .table-container{
        padding: 15px 20px !important;
    }
    .stt-settings p, label{
        font-size: 15px;
        font-weight: bold;
    }
    .stt-settings .add-payment{
        width: 100%;
        font-size: 15px;
    }
    .auto-renewal{
        flex-direction: column;
        gap: 30px;
        margin: 25px 0;
    }
  }