.trial-notification {
  display: flex;
  align-items: center;
  background-color: #1a1f2c;
  padding: 2rem;
  border-radius: 2rem;
  color: #fff;
  font-family: Arial, sans-serif;
  width: 100%;
  height: 18rem;
  margin-block-end: 2.5rem;
}

.trial-content {
  flex: 1;
}

.trial-text {
  font-size: 2.3rem;
  margin: 0;
  text-transform: capitalize;
}

.progress-bar {
  width: 85%;
  background-color: #333;
  height: 4px;
  border-radius: 2px;
  margin: 10px 0;
}

.progress {
  background-color: #888;
  height: 100%;
  border-radius: 2px;
}

.trial-remaining {
  font-size: 1.6rem;
  color: #ccc;
}

.time-boxes {
  display: flex;
  gap: 1rem;
  margin-right: 2rem;
  text-transform: capitalize;
}

.time-box {
  background-color: #2c2c3e;
  padding: 1rem;
  border-radius: 1rem;
  text-align: center;
  font-size: 1.4rem;
  color: #fff;
  min-width: 15rem;
}
.time-box span {
  display: block;
  margin-top: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: white !important;
}

.used-time {
  color: #ff3434;
}

.remaining-time {
  color: #129d54;
}

.upgrade-button {
  background-color: #415fac !important;
  color: #fff;
  border: none;
  padding: 3rem 0rem;
  border-radius: 10rem;
  cursor: pointer;
  font-size: 1.6rem;
  width: 20rem;
}

.upgrade-button:hover {
  background-color: #565ece;
}
