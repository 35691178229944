.item-img{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 15px;
}
.item-img .upload-img{
    border: 1px solid #E2E2E2;
    border-radius: 50%;
    padding: 15px;
    /* margin-right: 15px; */
}
.item-img .upload{
    font-size: 16px;
    font-weight: bold;
    color: #134A7C;
    text-decoration: underline;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
}
.item-img .drag{
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
}
.form-select{
    margin-bottom: 15px !important;
}
@media (max-width:525px) {
    .item-img{
        flex-direction: column;
    }
    .upload-photo{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
    }
}