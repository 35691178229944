  .wrapper{
    border-bottom: 1px solid black;
    overflow: hidden;
    width: 75%;
    margin: auto;
  }
  .wrapper .question-container{
    color: white;
    width: 100%;
    text-align: left;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .question-container.active{
    color: #415FAC;
    font-weight: bold;
    background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
  }
  .wrapper .question-container:hover{
    background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
  }
  .wrapper .answer-container ul li{
    color: rgba(255, 255, 255, 0.50);
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
  .wrapper .arrow{
    font-size: 20px;
    transition: .5s ease-in-out;
  }
  .arrow.active{
    rotate: 45deg;
    color: #415FAC;
  }
  .wrapper .answer-container{
    padding: 0 1rem;
    transition: height .7s ease-in-out;
  }
  .wrapper .answer-content{
    padding: 1rem 0;
    font-size: 20px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.50);
  }
  @media (max-width:768px) {
    .faq-section h1{
        font-size: 28px;
    }
    .wrapper{
        width: 100%;
    }
    .wrapper .question-container{
        padding: 20px 0;
        font-size: 18px;
    }
    .wrapper .question-container p{
        width: 90%;
    }
    .wrapper .answer-container{
        padding: 0;
    }
  }