.menus-nav {
  background-color: rgba(249, 249, 249, 1) !important;
  height: 65px;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
}
.menus-nav a.active {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  border-bottom: 3px solid rgba(255, 215, 52, 1) !important;
}
.menus-nav a {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.5) !important;
  border: none !important;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 30px;
}

@media (max-width: 525px) {
  .menus-nav {
    gap: 20px;
  }
  .menus-nav a {
    font-size: 15px;
    text-wrap: nowrap;
    padding: 0 20px;
  }
}
