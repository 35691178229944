.add-item-btn{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    background-color: #415FAC;
    outline: none;
    border: 0;
    color: white;
    border-radius: 100px;
    padding: 15px 0;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
}
.menu-item-container{
    display: flex;
    align-items: center;
    gap: 5px;
}
.menu-item-container img{
    width: 35px;
}
.menu-item-container h2 {
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    margin-bottom: 0;
    text-transform: capitalize;
}
.menu-item-container span {
    font-size: 12px;
}
.menu table thead tr th{
    padding-bottom: 25px;
}
.menu table tbody td span, .menu table tbody td {
    padding-bottom: 0;
}
.menu-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}